.page-account {
  .form-remove-saved-document {
      text-align: center;
      padding-right: 30px;
      @include breakpoint('small') {
          text-align: left;
      }
  }
  .page-preferences.js-preferences-user-container {
    > table.sortable-table.js-sortable-table {
      margin-bottom: 4.5rem;
    }
    .js-no-articles {
      font-size: 1.125rem;
      margin-bottom: 2rem;
    }
  }
  .sortable-table__col {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  &.page-account-bookmark,
  &.page-account-saved-searches {
    .sortable-table__header {
      & > tr > td {
        &:last-child {
          cursor: initial;
        }
      }
    }
    .lightbox-modal__title {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sortable-table__col--title a {
      &:hover {
        color: $omd-purple;
      }
    }
  }
  &.page-account-saved-searches {
    .page-account__introduction {
      margin-bottom: 0px;
      strong {
        font-weight: $font-weight-bold;
      }
    }
  }
}