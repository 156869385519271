.collections-set {
  background-color: $omd-light-grey;
  border-radius: 8px;
  margin: 0 0 50px;
  &.version-2 {
    margin-top: map-get($spacers, 1)*5;
    margin-bottom: map-get($spacers, 1)*5;
    @include breakpoint("medium") {
      margin-top: map-get($spacers, 1)*3.5;
      margin-bottom: map-get($spacers, 1)*3.5;
    }
  }
  &.no-top-margin {
		@include spacer(margin-top, map-get($spacers, 0));
  }
  &.no-bottom-margin {
		@include spacer(margin-bottom, map-get($spacers, 0));
  }
  @include breakpoint("lg") {
    margin: 30px;
  }
  @include breakpoint("medium") {
    margin: 30px 0;
  }
  .accordion-header {
    display: flex;
    position: relative;
    flex-direction: column;
    p {
      font-size: $font-size-20;
      line-height: 30px;
      color: $color-black;
      font-weight: $font-weight-400;
    }
  }
  .title {
    font-style: $font-style-normal;
    font-weight: $font-weight-600;
    font-size: $font-size-40;
    line-height: 50px;
    margin-bottom: 30px;
    padding-right: 30px;
    @include breakpoint("lg") {
      font-size: $h4-font-size;
    }
    &:before {
      content: " ";
      background: url("/dist/img/plus.png");
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      top: 10px;
      right: 0;
      height: 24px;
      width: 24px;
    }
  }
  .accordion-header {
    cursor: pointer;
  }
  .qc-title {
    font-size: $font-size-20;
    line-height: 28px;
    color: $color-black;
    font-weight: $font-weight-600;
    position: relative;
    padding-right: 30px;
    .action-flag {
      position: absolute;
      right: 0;
      top: -6px;
    }
  }
  .collections-aquarterly-class {
    margin-top: 35px;
    .inf-col-fluid {
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      border-left: 1px solid $omd-light-purple;
      width: 33.33333%;
      margin-bottom: 50px;
      @include breakpoint("lg") {
        width: 100%;
        border-left: 0px solid $omd-light-purple;
        border-bottom: 1px solid $omd-light-purple;
        padding-bottom: 30px;
        margin-bottom: 30px;
      }
    }
    .inf-col-fluid:nth-child(3n + 1) {
      border-left: 0 !important;
    }
    .qc-class {
      display: flex;
      flex-direction: column;
      height: 100%;
      .qc-title {
        flex: 1;
      }
    }    
    a.btn.secondary.omd-icon-arrow {
      margin: 25px 0 0 0;
    }
    span.qc-link {
      color: $omd-purple;
      font-size: $font-size-14;
      line-height: 20px;
      text-transform: uppercase;
      margin-top: 20px;
      letter-spacing: $letter-spacing-117;
      font-weight: $font-weight-600;
      &:hover {
        text-decoration: none;
      }
    }
    p.date {
      font-size: $font-size-14;
      font-weight: $font-weight-600;
      margin: 20px 0 0;
      span {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
      }
    }
  }
  .accordion-panel.accordion-expanded {
    .title {
      &:before {
        background: url("/dist/img/minus.png");
      }
    }
    .accordion-panel > .accordion-body {
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.2s ease;
    }
    .accordion-panel.accordion-expanded > .accordion-body {
      max-height: 2000px;
      margin-top: 30px;
    }
  }
  .accordion-panel > .accordion-body {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease;
  }
  .accordion-panel.accordion-expanded > .accordion-body {
    max-height: 2000px;
    margin-top: 25px;
  }
  .accordion-panel.accordion-expanded {
    padding: 40px 40px 0px;
    @include breakpoint("lg") {
      padding: 20px 20px 0px;
    }
  }
  .accordion-panel {
    padding: 40px;
    @include breakpoint("lg") {
      padding: 20px;
    }
  }
}

.part-of-collection {
  width: 100%;
  p {
    margin: 0 0 15px;
    font-weight: $font-weight-600;
  }
  a {
    @include breakpoint('small') {
      width: 100%;
    } 
  }
  &.one-line {
    p {
      float: left;
      margin: 11px 5px 15px 0;
    }
  }
}

.search-result__body {
  .part-of-collection {
    margin-top: 20px;
    order: 9;
  }
}

.omd-col-content-side {
  .part-of-collection {
    margin: 0 0 30px;
  }
}

.article-preview,
.featured-research-container {
  .part-of-collection {
    margin: 20px 0 0;
  }
}
